// @import '~react-toastify/scss/main';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "SourceSans3", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom-radio.css */
.custom-radio .ant-radio-wrapper-checked .ant-radio-inner {
  background-color: var(--active-color) !important;
  border-color: var(--active-color) !important;
}

.custom-radio .ant-radio-wrapper-checked .ant-radio {
  border-color: var(--active-color) !important;
}

:where(.css-dev-only-do-not-override-1a3hsjr).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: var(--active-color) !important;
  background-color: var(--active-color) !important;
}

.form-check-input:checked {
  background-color: var(
    --active-color,
    #0d6efd
  ) !important; /* Default color jika var tidak ada */
  border-color: var(--active-color, #0d6efd) !important;
}
.custom-select-ant .ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #ffffff !important;
}
.custom-select-ant .ant-select-selection-placeholder {
  width: 100%;
  padding-bottom: 5px;
  margin-top: 5px;
  font-family: SourceSans3;
  font-size: 14px;
  color: #818181;
  outline: none;
  padding: 8px;
}
