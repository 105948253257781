@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-SemiBold.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SourceSans3";
  src: url("../fonts/SourceSans3-SemiBoldItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
