@import "assets/styles/colors";
@import "assets/styles/fonts";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.active-menu {
  height: 5px;
  transform: translateY(16px);
  filter: contrast(0.6);
}

.heading1 {
  font-family: SourceSans3;
  font-weight: 100;
  font-size: 30px;
}

.heading2 {
  font-family: SourceSans3;
  font-size: 16px;
  font-weight: bold;
}

.heading3 {
  font-family: SourceSans3;
  font-weight: 300;
  font-size: 12px;
}

.custom-form-label {
  font-family: SourceSans3;
  font-size: 14px;
  color: #000;
  font-weight: 700;
}

.custom-card {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  padding: 20px;
}

.custom-card1 {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.dropdown-text {
  color: $darkgrey;
}

.dropdown-text:hover {
  color: $greyblue;
}

.regular-text {
  color: #000;
  font-family: SourceSans3;
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}

.pointer {
  cursor: pointer;
}

.bg-light-grey {
  background-color: #fcfcfc !important;
}

.bg-lightgrey {
  background-color: #e1e1e1;
}

.check-input {
  background-color: $greyblue;
  border-color: $greyblue;
}

.disabled {
  cursor: not-allowed !important;
}

.custom-hover-greyblue {
  color: #282c34;
}

.custom-hover-greyblue:hover {
  color: $greyblue;
}

.bg-gray {
  background-color: #e2e2e2;
}

.online {
  color: #00ff00;
}

.dropdown-toggle::after {
  content: none !important;
}

.custom-menu {
  text-align: left; /* Aligns text to the left */
}

.custom-menu .ant-menu-item {
  background: none !important; /* Removes background */
  margin: 0 !important; /* Removes margin */
  padding: 0 !important; /* Removes padding */
}

/* Optional: Additional customization to ensure no default styles */
.custom-menu .ant-menu-item-selected {
  color: inherit !important; /* Resets selected item color */
  background: none !important; /* Resets selected item background */
}

.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px !important;
  margin-right: 10px !important;
  order: -1 !important;
}
.ant-picker-input {
  font-size: 20px !important;
}
.ant-picker-input > input::placeholder {
  color: black !important;
}

.anticon .anticon-swap-right {
  display: none !important;
}

.custom-select .ant-select-selector {
  border: none !important;
  font-size: 16px !important;
  color: #000 !important;
}

.ant-form {
  font-family: "SourceSans3";
}
