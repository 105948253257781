@import "../../assets/styles/colors";

.header-owner {
  background: $darkaquamarine;
  // height: 60px
}

.header-owner a {
  color: #fff;
  font-family: SourceSans3;
}

.subheader {
  background: #0f796b;
  // height: 250px;
}

.add-student-form {
  padding: 50px 100px 50px 100px;
}
