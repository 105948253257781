@import "../../assets/styles/colors";

.footer {
  display: flex;
  width: 100%;
  text-align: center;
  background: $purplishbrown;
  color: $white;
  height: 40px;
  font-family: SourceSans3;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
