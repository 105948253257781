@import '../../assets/styles/colors';

.header{
    background: $darkaquamarine;
    height: 60px
}

.subheader{
    background: $lightaquamarine;
    height: 165px;
}