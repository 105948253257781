.list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 40px;
}

.list li {
  font-family: SourceSans3;
  font-size: 12px;
  margin-top: 5px;
}

.url-warning {
  width: 100%;
  background: #fdfdfd;
  border: 1px solid #e26851;
  border-radius: 5px;
  padding: 12px 10px 10px 12px;
  height: 50px;
}

.gray-text {
  font-family: SourceSans3;
  color: "#afafaf";
  font-size: 14px;
  margin-top: 5;
  margin-bottom: 5;
}

.Please-wait-were-creating-your-online-studio {
  width: 349px;
  height: 74px;
  font-family: SourceSans3;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
}

.upload-profile-picture {
  border: 1px solid #e26851;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 20px;
  p {
    font-size: 12px;
    margin: 0 !important;
  }
  button {
    width: 120px;
    height: 32px;
    background-color: #e26851;
    border-radius: 0;
  }
  span {
    font-size: 7px;
  }
}

.spiner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-plan label {
  font-weight: 500;
}
