@import "../../assets/styles/colors";

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-input {
  width: 100%;
  border: none;
  border: 1px solid #e0e0e0;
  padding-bottom: 5px;
  margin-top: 5px;
  font-family: SourceSans3;
  font-size: 14px;
  color: #000;
  outline: none;
  border-radius: 4px;
  padding: 8px;
}
