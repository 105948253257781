.list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 40px;
}

.list li {
  font-family: SourceSans3;
  font-size: 12px;
  margin-top: 5px;
}

.url-warning {
  width: 100%;
  background: #fdfdfd;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 12px 10px 10px 12px;
  height: 50px;
}

.gray-text {
  font-family: SourceSans3;
  color: "#afafaf";
  font-size: 14px;
  margin-top: 5;
  margin-bottom: 5;
}

.Image-9 {
  width: 238px;
  height: 208px;
  margin: 0 56px 23px 55px;
  object-fit: contain;
}

.textWellcome {
  width: 349px;
  height: 92px;
  // margin: 0 0 0;
  font-family: SourceSans3;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.textTitle {
  // width: 349px;
  // height: 92px;
  margin-bottom: 20px;
  font-family: SourceSans3;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  align-content: flex-end;
}

.textHeader {
  margin-bottom: 10px;
  font-family: SourceSans3;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  align-content: flex-end;
  color: black;
}

.Basic-steps-to-complete-your-studio- {
  width: 610px;
  height: 16px;
  margin: 0 0 10px;
  font-family: SourceSans3;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
}

.textHeaderList {
  font-family: SourceSans3;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  align-content: flex-end;
}

.textBodyList {
  font-family: SourceSans3;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  align-content: flex-end;
}

.row-bodylist {
  h6,
  p {
    font-family: SourceSans3;
    margin: 0;
  }

  p {
    font-size: 14px;
  }

  padding: 20px 0;
  border-bottom: 3px solid #efefef;
}
