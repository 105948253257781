.cardDashboard {
  display: flex;
  flex-direction: column;
  padding: 0;
  // border: 1px solid rgba(226, 105, 81, 0.2);
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  color: #000 !important;
}

.cardDashboard:hover {
  opacity: 0.7;
}

.titleCard {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
}

.bodyCard {
  font-size: 32px;
}

.footerCard {
  color: #637381;
  font-size: 12px;
}
