@import "../../assets/styles/colors";

.header-owner a {
  color: #fff;
  font-family: SourceSans3;
}

.subheader {
  background: #0f796b;
  // height: 250px;
}

.add-student-form {
  padding: 50px 100px 50px 100px;
}

.active {
  font-weight: 700;
  color: #ffcd00 !important;

  // background-color: #26a69a !important;
}

.active a {
  color: #ffcd00 !important;
}

.nav-link:hover {
  color: #ffcd00 !important;
}

.dot {
  width: 12px;
  height: 12px;
  border: 1px solid #919eab;
}

.dot-active {
  width: 12px;
  height: 12px;
  background-color: #26a69a !important;
}
