@import "../../../../assets/styles/colors";

.option-button {
  padding: 0;
  width: 25px;
  height: 25px;
  border: 1px solid $greyblue;
  border-radius: 5px;
  background: none;
  color: $greyblue;
  font-weight: bold;
}

.option-button:hover {
  background: $greyblue;
  color: #fff;
}

.single-student {
  h6,
  p {
    font-family: SourceSans3;
    margin: 0;
  }

  p {
    font-size: 14px;
  }

  padding: 20px 0;
  border-bottom: 3px solid #efefef;
}

.br-greyblue {
  border-right: 1px solid $greyblue;
}

.custom-hover4 {
  background-color: #fff;
}

.custom-hover4:hover {
  background-color: $greyblue;
  color: white;
}
